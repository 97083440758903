import { useState } from "react";
import { Link, useLocation  } from "react-router-dom";
import { Row, Col, Drawer } from "antd";
import "antd/dist/antd.css";
import "./index.css";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  GlowImg,
  Container,
} from "./styles";

function Header(){
  const [visible, setVisibility] = useState(false);

  
 /* const { active, account, library, connector, activate, deactivate } = useWeb3React()
  <Button onClick={connect} >Connect to MetaMask</Button>
      {active ? <p>Connected with {account}</p> : <p>no funca la wea</p>}
      <Button onClick={disconnect} >Disconnect</Button>
  */
  useLocation()
  var Home = true;
  var Token = false;
  if((window.location.href.indexOf("/farms") > -1) || (window.location.href.indexOf("/mint") > -1) ||  (window.location.href.indexOf("/token") > -1))
  {
    Home = false
  }
  

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(false);
    window.scrollTo({top: 0});
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
      
    };
    return (
      <>
          <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://zkrise.com">
                <Span >Home</Span>
                </a>
                </CustomNavLinkSmall>
          
          
          {/*<CustomNavLinkSmall >
            <a href="https://gateway.boba.network/">
              <Span>Bridge</Span>
            </a>
          </CustomNavLinkSmall>*/}
          { Home ? 
        <>
        
        
        
                
        </>
        :
        <>
        
        </>
        }
        <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://app.mute.io/swap?outputCurrency=0x7c9becb53a7702244ca260fd01b4748756ce7bb3" target="_blank" rel="noreferrer">
                <Span >Buy</Span>
                </a>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://dexscreener.com/zksync/0xf9a7ff47a346e242590f87546d1995de91b8b8b1" target="_blank" rel="noreferrer">
                <Span >Chart</Span>
                </a>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="/whitepaper.pdf" target="_blank">
                <Span >Whitepaper</Span>
                </a>
                </CustomNavLinkSmall>
        <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="#" target="_blank" rel="noreferrer">
                <Span >Liquidity Locker</Span>
                </a>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://zkrise.com/farms">
                <Span >Farms</Span>
                </a>
                </CustomNavLinkSmall>
        {/*
        <CustomNavLinkSmall >
            <Span><Link to="/" onClick={onClose}>Home</Link></Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall >
            <Span><Link to="/mint" onClick={onClose}>Mint</Link></Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall >
            <Span><Link to="/myMaidens" onClick={onClose}>My Maidens</Link></Span>
        </CustomNavLinkSmall>
      */}


      </>
    );
  };

  return (

    
    <HeaderSection style={{}} id="header">
      <Container>
        <Row justify="space-between">
          <Col span={4} >
            <LogoContainer to="/" aria-label="homepage" onClick={onClose} >
              <GlowImg src="/logoalt.png" alt="logoalt.png" style={{display: "block",maxWidth:"171px",maxHeight:"72px",width:"auto",height:"auto"}} /> 
            </LogoContainer>
          </Col>
          
          
          
            
            <NotHidden>
            <Col span={24} style={{textAlign:"right"}}>
              <MenuItem />

                <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://twitter.com/zkriseofficial" target="_blank" rel="noreferrer">
                  <img src="/img/twt.svg" alt="twt.svg"  style={{width:"32px",height:"32px"}}/>
                </a>
                </CustomNavLinkSmall>
              <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://t.me/zkriseportal" target="_blank" rel="noreferrer">     
                      <img src="/img/tg.svg" alt="tg.svg"  style={{width:"32px",height:"32px"}}/>
                </a>
              </CustomNavLinkSmall>
              </Col>
              </NotHidden>
              
          
          <Burger onClick={showDrawer}>
          <Col span={12}>
            <Outline />
            </Col>
          </Burger>
          
          
          
        </Row>
        
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
                
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
          <div style={{textAlign:"center", paddingTop:"16px"}}>

                  <a style={{justifyContent:"center",paddingRight:"16px"}} href="https://t.me/zkriseportal" target="_blank" rel="noreferrer">
                    <img src="/img/tg.svg" alt="tg.svg"  style={{width:"32px",height:"32px"}}/>
                  </a>



                  <a style={{justifyContent:"center",paddingRight:"16px"}} href="https://twitter.com/zkriseofficial" target="_blank" rel="noreferrer">
                    <img src="/img/twt.svg" alt="twt.svg"  style={{width:"32px",height:"32px"}}/>
                  </a>

          </div>
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default Header;
