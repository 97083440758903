import Container from "../../common/Container";
import { Row, Col } from "antd";
import {
  DeskDiv,
  MobileDiv,
  FooterSection
} from "./styles";


const Footer = () => {
  return (
    <>
      <div style={{color:"#fff",paddingTop:"36px",paddingBottom:"36px"}}>
        <FooterSection>
        <DeskDiv>
        <Row justify="space-between">
          <Col>
            <Row style={{paddingBottom:"16px"}}>
              <img src="/logo.png" alt="logo.png" style={{display: "block",maxWidth:"171px",maxHeight:"72px",width:"auto",height:"auto"}}/>
            </Row>
            <Row >
            <p>© 2023, ZKRISE</p>
            </Row>
        
          </Col>
          <Col>
              <Row style={{paddingBottom:"8px",justifyContent:"center",textAlign:"center"}}>
                <p>Join our socials!</p>
              </Row>
              <Row style={{justifyContent:"center"}}>

                <a style={{justifyContent:"center", paddingRight:"12px"}} href="https://twitter.com/zkriseofficial" target="_blank" rel="noreferrer">
                <Col>
                  
                    <img src="/img/twt.svg" alt="twt.svg"  style={{width:"48px",height:"48px", padding:"6px"}}/>
                  
                </Col>
                </a>
                <a style={{justifyContent:"center", paddingRight:"12px"}} href="https://t.me/zkriseportal" target="_blank" rel="noreferrer">
                  <Col style={{}}>
                    
                      <img src="/img/tg.svg" alt="tg.svg"  style={{width:"48px",height:"48px", padding:"6px"}}/>
                    
                  </Col>
                  </a>

              </Row>


              
              
          </Col>
        </Row>
        </DeskDiv>
        <MobileDiv>
            <Row style={{paddingBottom:"16px",justifyContent:"center"}}>
              <img src="/logo.png" alt="logo.png" style={{maxWidth:"171px",maxHeight:"72px",width:"auto",height:"auto"}}/>
            </Row>
            <Row style={{justifyContent:"center"}}>
            <p>© 2023, ZKRISE</p>
            </Row>
        

              <Row style={{paddingBottom:"0px",justifyContent:"center",textAlign:"center"}}>
                <p>Join our socials!</p>
              </Row>
              <Row style={{justifyContent:"center"}}>
                <a style={{justifyContent:"center", paddingRight:"12px"}} href="https://t.me/zkriseportal" target="_blank" rel="noreferrer">
                <Col>
                  
                    <img src="/img/tg.svg" alt="tg.svg"  style={{width:"48px",height:"48px", padding:"6px"}}/>
                  
                </Col>
                </a>
                <a style={{justifyContent:"center", paddingRight:"12px"}} href="https://twitter.com/zkriseofficial" target="_blank" rel="noreferrer">
                <Col>
                  
                    <img src="/img/twt.svg" alt="twt.svg"  style={{width:"48px",height:"48px", padding:"6px"}}/>
                  
                </Col>
                </a>


              </Row>


        </MobileDiv>
        
        </FooterSection>
      </div>

    </>
  );
};

export default (Footer);
