import styled from "styled-components";

export const BackgroundContainer = styled("section")`
background: #000;
background-image: url("/img/background.svg");
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
  
  overflow-x: hidden;
  @media only screen and (max-width: 768px) {
    background-size:300%;
    background-position:center;
  }

  min-height:100vh;
`;
/*
background-image: url("/bgs/0.png");
background-size:contain;
background-repeat:no-repeat;
background-position:center;
*/